<template>
  <div class="w-100 mt-10">
    <div class="d-block md:flex justify-between">

    </div>
    <vs-card style="flex-direction: column-reverse" class="shadow-lg mr-2 p-1 lg:w-12/12 md:w-12/12 sm:w-12/12 sm:flex-col  rounded-lg shadow-sm bg-h-primary-light light-border">
      <div style="flex-direction: column" class="flex lg:flex-row sm:flex-column md:flex-column ">
        <div class="w-100 flex justify-content-start flex-col lg:ml-5 ">
          <div class="flex my-3 justify-content-between items-center ">
            <div>
              <vs-button to="/vdi/create" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary  rounded text-sm md:text-base py-1 mb-2">ساخت دسکتاپ جدید</vs-button>
              &nbsp; <a target="_blank" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary rounded text-sm md:text-base py-1 mb-2 text-white" href="https://www.haio.ir/%D8%B1%D8%A7%D9%87%D9%86%D9%85%D8%A7%DB%8C-%D8%A7%D8%AA%D8%B5%D8%A7%D9%84-%D8%A8%D9%87-%D8%AF%D8%B3%DA%A9%D8%AA%D8%A7%D9%BE-%D9%85%D8%AC%D8%A7%D8%B2%DB%8C/">آموزش اتصال به دسکتاپ مجازی</a>
            </div>
          </div>
          <br />
          <div class="mt-5">
            <div style="width: 100%">
              <div v-if="cloudList == '' " class="text-center">
                <span class="text-lg mb-4 block">شما درحال حاضر دسکتاپ مجازی ندارید</span>
                <div>
                  <vs-button to="/vdi/create" class="cursor-pointer btn bg-h-secondary border-0 btn-secondary  rounded text-sm md:text-base py-1 mb-2">ساخت دسکتاپ جدید</vs-button>
                </div>
              </div>
              <div
                v-for="cloud in cloudList"
                :key="cloud.id"
                class="row bg-h-primary mx-3 mb-2 rounded-lg p-3 items-center justify-between md:justify-center">

                <div class="flex justify-start items-center  w-full lg:w-1/3">
                  <div class="flex gap-4 items-center w-10/12 lg:w-1/3">
                    <div v-if="cloud.vm_status_title === 'آنلاین'">
                      <div class="bg-h-success rounded-full w-4 h-4"></div>
                    </div>

                    <div v-else-if="cloud.vm_status_title !== 'آنلاین'">
                      <div class="bg-h-danger rounded-full w-4 h-4"></div>
                    </div>

                    <div>
                      <img width="30" class="mr-2 "
                           :src="`data:image/png;base64,${cloud.image.image_logo.attachment_content}`" />
                    </div>

                    <div>
                      <img width="30" class="mr-2"
                           :src="`data:image/png;base64,${cloud.country.country_flag_file.attachment_content}`" />
                    </div>
                  </div>

                  <div class="w-1/2 text-right">
                    <router-link  class="text-right text-sm md:text-lg text-white ir-yekan-bold cursor-pointer"  :to="`/vdi/details/${cloud.id}`" >{{cloud.title}}</router-link>
                    <div class="text-right text-xs md:text-sm font-light">{{ cloud.image.title }} </div>
                  </div>

                </div>
                <div class="flex items-center mt-3 md:mt-0 w-full md:w-1/3 justify-center"
                     v-if="cloud.vm_status_title !== 'در حال آماده سازی'"        >
                  <div
                    v-if="cloud.vm_status_title === 'آنلاین'"
                    :id="'power' + cloud.id"
                    title="خاموش کردن"
                    class="hover:shadow-xl  m-2 p-2  hover:text-white cursor-pointer"
                    @click="shutdownPrompt = true, id = cloud.id">
                    <b-tooltip variant="info" :target="'power' + cloud.id" triggers="hover">
                      خاموش کردن
                    </b-tooltip>
                    <b-icon class="my-2" scale="2" icon="power"></b-icon>
                  </div>
                  <div
                    v-if="cloud.vm_status_title === 'خاموش'"
                    id="poweron"
                    title="روشن کردن"
                    class="hover:shadow-xl  m-2 p-2  hover:text-white cursor-pointer"
                    @click="popupActiveReset = true, id = cloud.id">
                    <b-tooltip variant="info" target="poweron" triggers="hover">
                      روشن کردن
                    </b-tooltip>
                    <b-icon class="my-2" scale="2" icon="power"></b-icon>
                  </div>
                  <div
                    v-if="cloud.vm_status_title !== 'خاموش'"
                    :id="'reboot' + cloud.id"
                    title="خاموش کردن"
                    class="hover:shadow-xl  m-2 p-2   hover:text-white cursor-pointer"
                    @click="popupActiveReset = true, id = cloud.id">
                    <b-icon class="my-2" scale="2" icon="arrow-clockwise" />
                    <b-tooltip variant="info" :target="'reboot' + cloud.id" triggers="hover">
                      ریبوت
                    </b-tooltip>
                  </div>
                  <div
                    :id="'delete' + cloud.id"
                    @click="setDeletePrompt (cloud.id), deletePrompt = true, id = cloud.id"
                    class="hover:shadow-xl m-2 p-2  hover:text-white cursor-pointer">
                    <b-tooltip variant="info" :target="'delete' + cloud.id" triggers="hover">
                      حذف سرور
                    </b-tooltip>
                    <b-icon class="my-2" scale="2" icon="trash"></b-icon>
                  </div>

                </div>
                <div v-else id="load">
                  <b-tooltip variant="info" target="load" triggers="hover">
                    {{getFlag(cloud.vm_status)}}
                    در حال ساخت
                  </b-tooltip>
                  <b-icon
                    class="h1 text-dark"
                    icon="gear"
                    animation="spin"
                  ></b-icon>
                </div>

                <div class="flex flex-wrap justify-center lg:justify-end md:mt-4 lg:mt-0 items-center gap-4 w-full md:w-full lg:w-1/3">

<!--                  <div v-if="cloud.vm_status_title === 'آنلاین'">-->
<!--                    <div class=" bg-h-info rounded p-2 py-4 text-center ">-->
<!--                      <span @click="getVDILink(cloud.id)" class="text-white text-sm md:text-base cursor-pointer"> ورود به دسکتاپ</span>-->

<!--                    </div>-->
<!--                  </div>-->

                  <div class=" bg-h-primary-light rounded p-2 text-center w-24 cursor-pointer">
                    <span @click="copyToClipboard(cloud.vm_sequence_id)" class="text-white text-sm md:text-lg d-block"> {{ cloud.vm_sequence_id }}</span>
                    <span class="text-grey font-light text-xs">شماره ماشین</span>
                  </div>

                  <router-link
                    :id="'details' + cloud.id"
                    class="hover:shadow-xl m-1 text-grey cursor-pointer"
                    :to="`/vdi/details/${cloud.id}`">
                    <b-tooltip variant="info" :target="'details' + cloud.id" triggers="hover">
                      مشخصات سرور
                    </b-tooltip>
                    <b-icon class="my-2" scale="2" icon="arrow-left-square" />
                  </router-link>

                </div>
              </div>


            </div>
            <vs-pagination
              class="center w-full"
              :total="countPage"
              v-model="pageNumber"
            />
          </div>
        </div>
      </div>

      <!-- New Service -->
      <!-- Reboot VM -->
      <vs-popup class="holamundo"  title="ری استارت کردن سرور" :active.sync="popupActiveReset">
        <div class="flex justify-content-center items-center flex-col">
          <div>
            <img width="200" src="../../../../assets/images/elements/cloud-outline.webp" alt="">
          </div>
          <div>
            آیا از ری استارت کردن سرور خود مطمئن هستید ؟
          </div>
          <div class="flex justify-content-between mt-4">
            <vs-button color="primary" @click="setRebootPrompt(id)" class="rounded">بله </vs-button>
          </div>
        </div>
      </vs-popup>
      <!-- ShutDown VM -->
      <vs-popup :active.sync="shutdownPrompt" title=" خاموش کردن">
        <div class="p-2">
          <h5>آیا مایل به خاموش کردن ابر هستید؟</h5>
          <vs-button icon="power" class="rounded float-right" @click="turnOff(id)"
          >خاموش کردن</vs-button
          >
        </div>
      </vs-popup>
      <!-- delete vira cloud prompt -->
      <div>
        <vs-popup :active.sync="deletePrompt" title="حذف  ابر">
          <div class="con-exemple-prompt">
            <div>
              <p>آیا از حذف اطمینان دارید. ؟</p>
              <span class="text-danger">نکته مهم:</span> <span>حداقل هزینه جهت ساخت مجدد سرور 20 هزار تومان است، دقت داشته باشید بعد از حذف سرور باید حداقل شارژ را برای ساخت مجدد داشته باشید.</span>
            </div>
            <vs-button
              :disabled="isDeleting"
              icon="delete"
              class="float-right rounded w-25 mt-4"
              color="danger"
              @click="deleteRecord(id)"
            >تایید حذف</vs-button>
          </div>
        </vs-popup>
      </div><!-- reboot vm prompt -->
    </vs-card>
    <!--  prompt area-->

    <!--end  prompt area-->
  </div>
</template>

<script>
import CountTo from 'vue-count-to'

export default {
  name: 'DesktopList',
  components: {
    CountTo
  },
  data () {
    return {
      popupActiveReset: false,
      shutdownPrompt: false,
      pageNumber: 0,
      search: '',
      deletePrompt: false,
      isDeleting: false,
      loading:false,
      activePrompt : false,
      selectedID: 0,
      cloudList: [],
      flag: '',
      countPage: 1,
      serverIdreboot: null,
      LoginToVDIPopup: false
    }
  },
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    },
    activeUser () {
      return this.$store.state.AppActiveUser
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  watch: {
    pageNumber () {
      this.getCloudList()
    },
    $route () {
      this.getCloudList()
    },
    search () {
      this.getCloudList()
    }
  },
  methods: {
    copyToClipboard (p) {
      navigator.clipboard.writeText(p).then(() => {
        this.$vs.notify({ text: ' کپی شد', color: 'success' })
      })
    },
    deleteRecord (id) {
      this.isDeleting = true
      this.$http
        .delete(`/cloud/desktop/${id}`, {
          confirm_code: this.requestCode
        })
        .then((res) => {
          this.getCloudList()
          this.$vs.notify({
            color: 'warning',
            text: res.data.message,
            title: 'حذف ویرا ابر'
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: err.response.data.message,
            text: 'حذف ویرا ابر'
          })
        })
        .finally(() => {
          this.getCloudList()
          this.deletePrompt = false
          this.isDeleting = false
        })
    },
    setDeletePrompt (d) {
      this.selectedID = d
      this.activePrompt = false
      this.deletePrompt = true
    },
    setRebootPrompt (d) {
      this.reboot(d)
      this.popupActiveReset = false
    },
    turnOff (id) {
      this.$http
        .get(`/cloud/desktop/${id}/shutdown`)
        .then((res) => {
          this.shutdownPrompt = false
          this.vdi = false
          this.getCloudList()
          this.$vs.notify({
            color: 'success',
            title: ' ',
            text: res.data.message
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: '  ',
            text: err.response.data.message
          })
        })
    },
    reboot (id) {
      this.rebootPrompt = false
      this.$http
        .get(`/cloud/desktop/${id}/reboot`)
        .then((res) => {
          this.vdi = false
          this.getCloudList()
          this.$vs.notify({
            color: 'success',
            title: ' ',
            text: res.data.message
          })
        })
        .catch((err) => {
          this.$vs.notify({
            color: 'danger',
            title: '  ',
            text: err.response.data.message
          })
        })
    },
    getCloudList () {
      this.$vs.loading()
      this.$http
        .get(
          `/cloud/desktop/?page=${this.pageNumber}&filter=${this.search}`
        )
        .then((res) => {
          this.$vs.loading.close()
          const d = res.data.params
          this.countPage = d.last_page
          this.cloudList = d.data
        })
        .catch((err) => {
          this.$vs.loading.close()
          if (err.response.status === 404) {
            this.cloudList = []
            return
          }

          this.$vs.notify({
            color: 'danger',
            title: ' ویرا کلود',
            text: err.response.data.message
          })
        })
    },
    getFlag (status) {
      if (status === 1) {
        this.repeat = setTimeout(() => {
          this.getCloudList()
        }, 60000)
      }
    },
    openNewPage (link) {
      window.open(link, '_block')
    },
    getVDILink (id) {
      this.$http.get(`/cloud/desktop/${id}/login`)
        .then(response => {
          this.VDILink = response.data.params.vdi_url
          this.openNewPage(this.VDILink)
        })
        .catch(error => {
          this.$vs.notify({
            text: error.response.data.message,
            color: 'danger'
          })
        })
    }

  },
  async mounted () {
    await this.getCloudList()
    await this.getFlag()
  },
  destroyed () {
    window.clearInterval(this.repeat)
  }
}
</script>

<style scoped>
.bg-box {
  background-color: #e9f1fa !important;
}
.white-light {
  background-color: #ffffff !important;
  border-radius: 1rem;
  filter: drop-shadow(5px 5px 10px #98befb);
}
.hoverItem:hover {
  transition: 0.2s;
  box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
}


</style>
